// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomScrollbar.css */

/* Width of the scrollbar */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/CustomScrollbar.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB,2BAA2B;AAC3B;IACI,UAAU;EACZ;;EAEA,UAAU;EACV;IACE,mBAAmB;EACrB;;EAEA,WAAW;EACX;IACE,gBAAgB;EAClB;;EAEA,oBAAoB;EACpB;IACE,gBAAgB;EAClB","sourcesContent":["/* CustomScrollbar.css */\n\n/* Width of the scrollbar */\n::-webkit-scrollbar {\n    width: 6px;\n  }\n  \n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #f1f1f1; \n  }\n  \n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background: #888; \n  }\n  \n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
