// SendOtpMutationRequest type
export const SendOtpMutationRequest = {
  mobileNumber: '',
  imeiNumber: '',
  role: ''
};

// SendOtpMutationResponse type
export const SendOtpMutationResponse = {
  status: '',
  sessionId: ''
};

// VerifyOtpMutationRequest type
export const VerifyOtpMutationRequest = {
  otp: '',
  sessionId: '',
  mobileNumber: '',
  imeiNumber: ''
};

// VerifyOtpMutationResponse type
export const VerifyOtpMutationResponse = {
  token: '',
  status: false
};
