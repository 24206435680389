import React from 'react';
// import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import Hero from './components/Hero';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/CustomScrollbar.css';
// import Analytics from './components/Analytics';
// import store from './store/config';
function App() {
  const [token, setToken] = React.useState('');
  return (
      <div className='w-full'>
        <ToastContainer/>
        <Navbar setToken={setToken} Token={token}/>
        <Slider/>
        <Hero Token={token} />
        <Footer/>
        {/* <Analytics /> */}
        {/* <Newsletter /> */}
        {/* <Cards /> */}
      </div>
  );
}

export default App;
