import { rootMiddleware, rootReducer } from './root-reducer';
import { configureStore } from '@reduxjs/toolkit';
import persistStore from 'redux-persist/es/persistStore';

// Configure and create the Redux store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      .concat(rootMiddleware),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(rootMiddleware)
});

// Export the store and persistor
export const persistor = persistStore(store);

// // Infer the `RootState` and `AppDispatch` types from the store itself
const getStateType = () => null;
export const RootState = getStateType();
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const AppDispatch = store.dispatch;
export const AppStore = store;
