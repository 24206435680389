import React from 'react';

const Footer = () => {
  return (
    <main className="w-full flex justify-center flex-wrap sm:flex-row-reverse gap-3 p-4 md:mt-6">
      <div className="px-4 max-w-[640px]">
        <h1 className="font-medium text-[36px] md:text-[50px] leading-10 md:leading-normal">
            Have you tried our
          <br />
          mobile app?
        </h1>

        <p className="py-8 text-xl md:text-2xl font-bold text-gray-600">
          Pitripaksh Mela mobile app covers the all details of Pitripaksh Mahasangam.
          <br />
          <br/>
          The aim of this app is to stand as a guide for visitors pilgrims visiting Gaya during Pitripaksh. The following information are provided in this app.
        </p>

        <button type="button" className="flex items-center justify-center w-48 mt-3 text-white bg-black rounded-lg h-14">
            <div className="mr-3">
                <svg viewBox="30 336.7 120.9 129.2" width="30">
                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z">
                    </path>
                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z">
                    </path>
                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z">
                    </path>
                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z">
                    </path>
                </svg>
            </div>
            <div>
                <div className="text-xs">
                    GET IT ON
                </div>
                <div className="-mt-1 font-sans text-xl font-semibold">
                    Google Play
                </div>
            </div>
        </button>
      </div>

      <div className="max-w-lg flex justify-center items-center">
        <img src="https://raw.githubusercontent.com/prajwal89/audiostory-app-landing-page/ec384e241176d1bfddac9df979c14e37a05a7afe/dist/static/watch_on_youtube.png" className="h-[600px]" alt="" />
      </div>
    </main>
  );
};

export default Footer;
