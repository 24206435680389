import { retry } from '@reduxjs/toolkit/query/react';
import { selectToken } from '../../store/auth/authSlice';

// returns a function which creates an axios base query
export function createAxiosBaseQueryCreator(axiosInstance, maxRetries = 1) {
 
 return retry(
   async (requestOpts, { getState }) => {
     try {
        const token = selectToken(getState());
        const result = await axiosInstance({
         ...requestOpts,
         params: {
           ...requestOpts.params,
         },
         headers: {
           ...requestOpts.headers,
           Authorization: token ? `Bearer ${token}` : undefined, // Use the fetched token
         }
       });

       return { data: result.data };
     } catch (axiosError) {
       const err = axiosError;
       return { error: { status: err.response?.status, data: err.response?.data } };
     }
   },
   { maxRetries }
 );
}
