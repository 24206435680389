import React, { useState } from "react";
import { useCreateBookingMutation } from "../services/api/ayanshala-backend";
import { toast } from 'react-toastify';
import { Bounce } from 'react-toastify';

const BookingModal = ({ onClose }) => {
    const [formData, setFormData] = useState({
        fullName: "",
        residentialAddress: {
            city: "",
            district: "",
            pincode: "",
            policeStation: "",
            state: ""
        },
        ancestralAddress: {
            city: "",
            district: "",
            pincode: "",
            policeStation: "",
            state: ""
        },
        bookingDate: ""
    });

    const [createBooking] = useCreateBookingMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [field, key] = name.split('.');

        if (key) {
            setFormData((prevData) => ({
                ...prevData,
                [field]: {
                    ...prevData[field],
                    [key]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await createBooking(formData);
            console.log("Booking created:", response);
            if (response.data) {
                toast.success(response.data, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                onClose();
            } else if (response.error) {
                toast.error(response.error.data, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } catch (error) {
            console.error("Error creating booking:", error);
            toast.error("Error creating booking", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    return (
        <div className="fixed grid place-items-center backdrop-blur-sm top-0 right-0 left-0 z-50 w-full inset-0 h-modal h-full justify-center items-center">
            <div className="relative container m-auto px-6">
                <div className="m-auto">
                    <div className="rounded-xl bg-white shadow-xl">
                        <div className="p-8">
                            <button
                                type="button"
                                onClick={onClose}
                                className="absolute top-2 right-8 p-2 text-cyan-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center popup-close"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="#c6c7c7"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Close popup</span>
                            </button>
                            <div className="space-y-1">
                                <h2 className="text-2xl text-center text-black font-bold">Book Now</h2>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mt-4">
                                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-2">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="fullName"
                                        name="fullName"
                                        className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="residentialAddress" className="block text-sm font-medium text-gray-700 mb-2">
                                        Residential Address
                                    </label>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="text"
                                            name="residentialAddress.city"
                                            placeholder="City"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.residentialAddress.city}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="residentialAddress.district"
                                            placeholder="District"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.residentialAddress.district}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="residentialAddress.pincode"
                                            placeholder="Pincode"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.residentialAddress.pincode}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="residentialAddress.policeStation"
                                            placeholder="Police Station"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.residentialAddress.policeStation}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="residentialAddress.state"
                                            placeholder="State"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.residentialAddress.state}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <label htmlFor="ancestralAddress" className="block text-sm font-medium text-gray-700 mb-2">
                                        Ancestral Address
                                    </label>
                                    <div className="grid grid-cols-2 gap-4">
                                        <input
                                            type="text"
                                            name="ancestralAddress.city"
                                            placeholder="City"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.ancestralAddress.city}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="ancestralAddress.district"
                                            placeholder="District"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.ancestralAddress.district}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="ancestralAddress.pincode"
                                            placeholder="Pincode"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.ancestralAddress.pincode}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="ancestralAddress.policeStation"
                                            placeholder="Police Station"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.ancestralAddress.policeStation}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="ancestralAddress.state"
                                            placeholder="State"
                                            className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                            value={formData.ancestralAddress.state}
                                            onChange={handleChange}
                                            maxLength="20"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <label htmlFor="bookingDate" className="block text-sm font-medium text-gray-700 mb-2">
                                        Booking Date
                                    </label>
                                    <input
                                        type="datetime-local"
                                        id="bookingDate"
                                        name="bookingDate"
                                        className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                        value={formData.bookingDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="mt-6">
                                    <button
                                        type="submit"
                                        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-800 hover:bg-orange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingModal;
