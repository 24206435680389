import { ayanshalaBackendApi } from '../ayanshala-backend';

const bookingApi = ayanshalaBackendApi.injectEndpoints({
  endpoints: (builder) => ({
    createBooking: builder.mutation({
      query: (bookingData) => ({
        url: `client/createBooking`,
        method: 'POST',
        data: bookingData,
      }),
    }),
    cancelBooking: builder.mutation({
      query: (bookingId) => ({
        url: `client/cancelBooking?bookingId=${bookingId}`, // Use bookingId in the URL
        method: 'PUT',
      }),
    }),
    getAllBookings: builder.query({
      query: () => ({
        url: `client/getAllBookings`,
        method: 'GET',
      }),
    }),
    // Add more endpoints as needed
  }),
});

export const {
  useCreateBookingMutation,
  useCancelBookingMutation,
  useGetAllBookingsQuery,
} = bookingApi;
