import React, { useState } from "react";
import { useSendOtpMutation } from "../services/api/ayanshala-backend/auth";

const LoginModal = ({ onClose, onOpenOtpModal, setPhoneNumber, setSessionId,uuid }) => {
    const [phoneNumber, setLocalPhoneNumber] = useState('');
    const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
    // make uuid a string
    const [SendOtpMutation] = useSendOtpMutation();
    

    const handleClose = () => {
        onClose();
    };

    const handlePhoneNumberChange = (event) => {
        setLocalPhoneNumber(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (phoneNumber.length !== 10) {
            setShowPhoneNumberError(true);
            return;
        }
        try {
            console.log("phone number:", phoneNumber);
            console.log("uuid:", uuid);
            const response = await SendOtpMutation({
                "mobileNumber": phoneNumber,
                "imeiNumber": uuid,
                "role": "CLIENT",
            })
            // 432517;
            console.log("response:", response);
            if (response) {
                setPhoneNumber(phoneNumber);
                setSessionId(response.data.sessionId);
                console.log("session_id:", response.data.sessionId);
                console.log("uuid:", uuid);
                console.log("Request OTP for phone number:", phoneNumber);
                handleClose();
                onOpenOtpModal();
            } else {
                console.log("Error in sending OTP1: ", response);
            }
        } catch (error) {
            console.log("Error in sending OTP2: ", error);
        }
    };
    

    return (
        <>
            <div className="fixed grid place-items-center backdrop-blur-sm top-0 right-0 left-0 z-50 w-full inset-0 h-modal h-full justify-center items-center">
                <div className="relative container m-auto px-6 md:w-7/12">
                    <div className="m-auto">
                        <div className="rounded-xl bg-white shadow-xl">
                            <div className="p-8 ">
                                <button type="button" onClick={handleClose}
                                    className="absolute top-2 right-8 p-2 text-cyan-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center popup-close"><svg
                                        aria-hidden="true" className="w-5 h-5" fill="#c6c7c7" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <span className="sr-only">Close popup</span>
                                </button>
                                <div className="space-y-1 ">
                                    <h2 className="text-2xl text-center text-black font-bold">Login</h2>
                                    <h4 className="mb-8 text-xl text-center text-black font-bold">Book your Panda Now.</h4>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-4">
                                        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-2">
                                            Enter your mobile number
                                        </label>
                                        <div className="mt-1 relative">
                                            <input
                                                type="tel"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                className="w-full p-2 border-gray-300 rounded-md shadow-sm focus:border-orange-400 focus:ring focus:ring-orange-400 focus:ring-opacity-50"
                                                placeholder="Enter your mobile number"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                            />
                                        </div>
                                            {showPhoneNumberError && phoneNumber.length !== 10 && (
                                                <p className="absolute text-left text-xs text-red-500 m-1">
                                                    Mobile number should be 10 digits long.
                                                </p>
                                            )}
                                    </div>

                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-800 hover:bg-orange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Request OTP
                                        </button>
                                    </div>
                                </form>
                                <div className="mt-14 space-y-4 py-3 text-gray-600 text-center">
                                    <p className="text-xs">By proceeding, you agree to our <button className="underline">Terms of Use</button> and confirm you have read our <button className="underline">Privacy and Cookie Statement</button>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginModal;
