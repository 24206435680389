import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import LoginModal from './LoginModal';
import OtpModal from './OtpModal';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearToken } from '../store/auth/authSlice';
import { toast } from 'react-toastify';
import { Bounce } from 'react-toastify';

const Navbar = () => {
    const uuid = uuidv4();
    const [nav, setNav] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [session_id, setSessionId] = useState('');
    const [uniqueId, setUniqueId] = useState('');
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const dispatch = useDispatch();

    const handleNav = () => {
        setNav(!nav);
    };

    const handleLoginClick = () => {
        setUniqueId(uuid);
        setShowLoginModal(true);
    };

    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
    };

    const handleCloseOtpModal = () => {
        setShowOtpModal(false);
    };

    const handleOpenOtpModal = () => {
        setShowOtpModal(true);
    };

    const handleBackToLogin = () => {
        setShowOtpModal(false);
        setShowLoginModal(true);
    };
    const handleLogOut = () => {
        toast.success('Logged out successfully',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
        dispatch(clearToken());
    
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;

            if (scrollTop > 50) {
                setHasScrolled(true);
            } else {
                setHasScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`flex sticky top-0 justify-between z-50 items-center h-24 w-full px-4 text-black bg-white ${hasScrolled ? 'shadow-md' : ''}`}>
            <h1 className='w-full text-3xl font-bold text-[black] m-10'>ShraadhPuja Booking</h1>
            <ul className='hidden md:flex m-10'>
                <li className='p-4'>Home</li>
                <li className='p-4'>Company</li>
                <li className='p-4'>Resources</li>
                <li className='p-4'>About</li>
                {isAuthenticated ? (
                    <li className='p-4 -m-2'>
                        <button onClick={handleLogOut} className="rounded-full bg-orange-800 text-white px-4 py-2 hover:text-white">Logout</button>
                    </li>
                ) : (
                    <li className='p-4 -m-2'>
                        <button onClick={handleLoginClick} className="rounded-full bg-orange-800 text-white px-4 py-2 hover:text-white">Login</button>
                    </li>
                )}

            </ul>
            <div onClick={handleNav} className='block md:hidden'>
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
            </div>
            <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                <h1 className='w-full text-3xl font-bold text-black m-4'>Options</h1>
                <li className='p-4 border-b border-gray-600'>Home</li>
                <li className='p-4 border-b border-gray-600'>Company</li>
                <li className='p-4 border-b border-gray-600'>Resources</li>
                <li className='p-4 border-b border-gray-600'>About</li>
                <li className='p-4'>Contact</li>
            </ul>
            {showLoginModal && <LoginModal onClose={handleCloseLoginModal} onOpenOtpModal={handleOpenOtpModal} setPhoneNumber={setPhoneNumber} setSessionId={setSessionId} uuid={uniqueId} />}
            {showOtpModal && <OtpModal onClose={handleCloseOtpModal} onBackToLogin={handleBackToLogin} phoneNumber={phoneNumber} session_id={session_id} uuid={uniqueId} setSessionId={setSessionId}/>}
        </div>
    );
};

export default Navbar;
