import React, { useState } from 'react';
import image1 from '../assets/fare.png';
import image2 from '../assets/package.png';
import image3 from '../assets/map.png';
import image4 from '../assets/samadhan.png';
import LoginModal from './LoginModal';
import OtpModal from './OtpModal';
import BookingModal from './BookingModal'; // Import the BookingModal component
import Booking from './Booking'; // Import the Booking component
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const Hero = ({ token }) => {
    const uuid = uuidv4();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [session_id, setSessionId] = useState('');
    const [uniqueId, setUniqueId] = useState('');
    const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
    const [isPreviousBookingOpen, setIsPreviousBookingOpen] = useState(false);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const openBookingModal = () => {
        if (!isAuthenticated) {
            setUniqueId(uuid);
            setShowLoginModal(true);
        } else {
            setIsBookingModalOpen(true);
        }
    };

    const openPreviousBooking = () => {
        if (!isAuthenticated) {
            setUniqueId(uuid);
            setShowLoginModal(true);
        } else {
            setIsPreviousBookingOpen(true);
        }
    };

    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
    };

    const handleCloseOtpModal = () => {
        setShowOtpModal(false);
    };

    const handleOpenOtpModal = () => {
        setShowOtpModal(true);
    };

    const handleBackToLogin = () => {
        setShowOtpModal(false);
        setShowLoginModal(true);
    };

    const closeBookingModal = () => {
        setIsBookingModalOpen(false);
    };

    const closePreviousBooking = () => {
        setIsPreviousBookingOpen(false);
    };

    const handleBookingSubmit = (data) => {
        console.log("Booking data:", data);
        closeBookingModal();
    };

    return (
        <section className="relative bg-gradient-to-r from-blue-200 to-orange-300">
            <div className="grid max-w-screen-xl px-8 pt-20 pb-8 mx-40 lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                    <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight">
                        Welcome <br />to the land of enlightenment <br/> and salvation.
                    </h1>
                    <div className="flex gap-8">
                        <ButtonWithImage image={image1} text="STATE FAIR" />
                        <ButtonWithImage image={image4} text="CALL FLOW" />
                        <ButtonWithImage image={image2} text="PACKAGE" />
                        <ButtonWithImage image={image3} text="MAP" />
                    </div>
                </div>
                <div className="lg:col-span-5 lg:place-self-start">
                    <p className="text-4xl font-extrabold mb-4">Book your Panda NOW!</p>
                    <button onClick={openBookingModal} className="px-10 py-4 relative group rounded-full overflow-hidden font-medium bg-purple-50 text-orange-800 inline-block text-xl">
                        <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-orange-800 group-hover:h-full opacity-90"></span>
                        <span className="relative group-hover:text-white">Book Now</span>
                    </button>
                    <br/>
                    {isAuthenticated && (
                        <button onClick={openPreviousBooking} className="mt-4 px-10 py-4 relative group rounded-full overflow-hidden font-medium bg-gray-50 text-gray-800 inline-block text-xl">
                            <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-gray-800 group-hover:h-full opacity-90"></span>
                            <span className="relative group-hover:text-white">Your Previous Booking</span>
                        </button>
                    )}
                </div>
            </div>

            {isBookingModalOpen && (
                <BookingModal onClose={closeBookingModal} onSubmit={handleBookingSubmit} />
            )}
            {isPreviousBookingOpen && (
                <Booking onClose={closePreviousBooking} />
            )}
            {showLoginModal && <LoginModal onClose={handleCloseLoginModal} onOpenOtpModal={handleOpenOtpModal} setPhoneNumber={setPhoneNumber} setSessionId={setSessionId} uuid={uniqueId} />}
            {showOtpModal && <OtpModal onClose={handleCloseOtpModal} onBackToLogin={handleBackToLogin} phoneNumber={phoneNumber} session_id={session_id} uuid={uniqueId}/>}
        </section>
    );
};

const ButtonWithImage = ({ image, text }) => {
    return (
        <div className="w-24 h-24 relative group cursor-pointer flex flex-col items-center border-white border rounded-lg p-3 transition duration-300 transform hover:scale-110 hover:bg-orange-800">
            <img src={image} alt="button" className="w-12 h-12 text-white transition duration-300 transform group-hover:scale-110" />
            <span className="mt-2 text-xs text-white transition duration-300 transform hover:scale-110">{text}</span>
            <div className="absolute inset-0 rounded-lg transition duration-300"></div>
        </div>
    );
};

export default Hero;
