import React, { useEffect } from 'react';
import { useGetAllBookingsQuery, useCancelBookingMutation } from '../services/api/ayanshala-backend/booking/booking';
import { format } from 'date-fns';

const Booking = ({ onClose }) => {
    const { data: bookings, error, isLoading, refetch } = useGetAllBookingsQuery(); // Use refetch function to fetch bookings

    const [cancelBooking] = useCancelBookingMutation();

    const handleCancelBooking = async (bookingId) => {
        try {
            await cancelBooking(bookingId);
            alert('Booking cancelled successfully!');
            refetch(); // Refetch bookings after cancellation
        } catch (err) {
            console.error('Failed to cancel booking:', err);
        }
    };

    useEffect(() => {
        refetch(); // Fetch bookings when the component mounts
    }, [refetch]); // Refetch when refetch function changes

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading bookings: {error.message}</div>;

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 overflow-y-auto">
            <div className="relative bg-white rounded-lg max-w-lg w-full m-6 shadow-xl">
                <div className="p-8 max-h-96 overflow-y-auto">
                    <h2 className="text-2xl text-center font-bold mb-4">Your Previous Bookings</h2>
                    <ul className="space-y-4">
                        {bookings.map((booking) => (
                            <li key={booking.id} className="p-4 border border-gray-300 rounded">
                                <p><strong>Full Name:</strong> {booking.fullName}</p>
                                <p><strong>Booking Date:</strong> {format(new Date(booking.bookingDate), 'dd/MM/yyyy')}</p>
                                <p><strong>Status:</strong> {booking.bookingStatus}</p>
                                <p><strong>Residential Address:</strong> {`${booking.residentialAddress.city}, ${booking.residentialAddress.district}, ${booking.residentialAddress.state}, ${booking.residentialAddress.pincode}`}</p>
                                <p><strong>Ancestral Address:</strong> {`${booking.ancestralAddress.city}, ${booking.ancestralAddress.district}, ${booking.ancestralAddress.state || ''}, ${booking.ancestralAddress.pincode}`}</p>
                                {booking.bookingStatus !== 'CANCELLED' && (
                                    <button
                                        onClick={() => handleCancelBooking(booking.id)}
                                        className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                                    >
                                        Cancel Booking
                                    </button>
                                )}
                                {booking.bookingStatus === 'CANCELLED' && (
                                    <button
                                        className="mt-2 px-4 py-2 bg-gray-400 text-white rounded cursor-not-allowed"
                                        disabled
                                    >
                                        Cancelled
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <button
                    onClick={onClose}
                    className="mt-4 w-full py-2 px-4 bg-gray-800 text-white rounded hover:bg-gray-900"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default Booking;
