import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "linear"
  };
  return (
  <div>
    <div className="w-full overflow-x-clip z-20">
      <Slider {...settings}>
        <img src={require('../assets/image2.jpg')} alt="image1" />
        <img src={require('../assets/image2.jpg')} alt="image2" />
        <img src={require('../assets/image2.jpg')} alt="image3" />
        <img src={require('../assets/image2.jpg')} alt="image4" />
      </Slider>
    </div>
    {/* <div className="h-5 w-full bg-gradient-to-r from-blue-600 to-orange-900 opacity-20 z-10"></div> */}
    </div>
  );
}
