import React, { useState, useRef, useEffect } from "react";
import { useVerifyOtpMutation } from "../services/api/ayanshala-backend/auth";
import { setToken } from "../store/auth/authSlice";
import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { useSendOtpMutation } from "../services/api/ayanshala-backend/auth";
import { toast } from "react-toastify";
import { Bounce } from "react-toastify";
// import { setToken } from "../store/auth/authSlice";

const OtpModal = ({ onClose, onBackToLogin, phoneNumber,session_id,uuid,setSessionId}) => {
    const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
    const [showVerificationError, setShowVerificationError] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendTime, setResendTime] = useState(30);
    const inputRefs = useRef([]);
    const dispatch = useDispatch();
    const [verifyOtpMutation] = useVerifyOtpMutation();
    const handleClose = () => {
        onClose();
    };
    const [SendOtpMutation] = useSendOtpMutation();

    const handleVerificationCodeChange = (index, value) => {
        const updatedCode = [...verificationCode];
        updatedCode[index] = value;

        if (value === '') {
            // Allow deletion even before typing all 6 digits
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (index < inputRefs.current.length - 1) {
            // Move focus to the next input field if not the last one
            inputRefs.current[index + 1].focus();
        }

        setVerificationCode(updatedCode);
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            if (verificationCode[index] === '' && index > 0) {
                // Move focus to the previous input field
                inputRefs.current[index - 1].focus();
                // Delete content of the previous field
                const updatedCode = [...verificationCode];
                updatedCode[index - 1] = '';
                setVerificationCode(updatedCode);
            } else {
                // Clear content of the current field
                const updatedCode = [...verificationCode];
                updatedCode[index] = '';
                setVerificationCode(updatedCode);
            }
        }
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        if (verificationCode.some(code => code === '')) {
            setShowVerificationError(true);
            return;
        }
        const code = verificationCode.join('');
        console.log("otp", code);
        console.log("session_id", session_id);
        console.log("uuid", uuid);
        try{
            const response = await verifyOtpMutation({
                "otp":code,
                "sessionId":session_id,
                "mobileNumber":phoneNumber,
                "imeiNumber":uuid,
                "role": "CLIENT"
            });
            if (response.data) {
                toast.success('Logged in Successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
                console.log("Response from verify OTP: ", response.data.token);
                dispatch(setToken({ token: response.data.token }));
                handleClose();
            } else {
                console.log("Error in verifying OTP1: ", response);
            }
        }
        catch(error){
            console.log("Error in verifying OTP: ", error);
            console.log("session_id:", session_id);
            console.log("uuid:", uuid);
            console.log("Request OTP for phone number:", phoneNumber);
        }
        // Here you can add logic to handle OTP verification
        console.log("Verify OTP:", code);
    };
    const handleResendClick = async() => {
        setResendTime(30); // Reset the timer
        setIsResendDisabled(true);
    
        // Start the timer again
        const timer = setInterval(() => {
            setResendTime(prevTime => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    setIsResendDisabled(false);
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
        try {
            const response = await SendOtpMutation({
                "mobileNumber": phoneNumber,
                "imeiNumber": uuid,
            });
            if (response.data.status === "Success") {
                setSessionId(response.data.sessionId);
                console.log("Request OTP for phone number:", phoneNumber);
            } else {
                console.log("Error in sending OTP1: ", response);
            }
        } catch (error) {
            console.log("Error in sending OTP2: ", error);
        }
    };
    

    useEffect(() => {
        const timer = setInterval(() => {
            setResendTime(prevTime => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    setIsResendDisabled(false);
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleChangeClick = () => {
        handleClose();
        onBackToLogin();
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-sm">
            <div className="w-full max-w-md px-8 py-10 bg-white rounded-lg shadow-md relative">
                <h1 className="text-2xl font-semibold text-center mb-6">Enter OTP</h1>
                <button type="button" onClick={handleClose} className="absolute top-2 right-2 p-2 text-cyan-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm inline-flex items-center popup-close">
                    <svg aria-hidden="true" className="w-5 h-5" fill="#c6c7c7" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">Close popup</span>
                </button>
                <div className="text-center items-center">
                    <p className="text-gray-600 text-center mb-4">Code sent to +91 {phoneNumber}
                    <button
                        className="px-1 text-sm font-medium text-center rounded text-orange-800 cursor-pointer"
                        onClick={handleChangeClick}
                        >
                        Change
                    </button>
                </p>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-6 gap-4">
                        {verificationCode.map((code, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength={1}
                                value={code}
                                ref={el => inputRefs.current[index] = el}
                                onChange={(e) => handleVerificationCodeChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                className="rounded-lg bg-gray-100 cursor-text w-14 aspect-square flex items-center justify-center text-gray-700 text-center border border-gray-300"
                            />
                        ))}
                    </div>
                    {showVerificationError && verificationCode.some(code => code === '') && (
                        <p className="text-left text-xs text-red-500 m-1">OTP should be 6 digits long.</p>
                    )}
                    <div className="flex items-center justify-center mt-6">
                        <button type="submit" className="w-full px-4 py-2 text-lg font-medium text-white bg-orange-800 rounded-md hover:bg-orange-900">Verify</button>
                    </div>
                </form>
                <div className="flex items-center flex-col justify-between mt-6">
                    <p className="text-gray-600 text-sm">Didn't receive code?</p>
                    <div className="flex items-center space-x-2">
                        <button
                            className={`px-3 py-2 text-sm font-medium text-center rounded ${isResendDisabled ? 'text-gray-500' : 'text-orange-800'} ${isResendDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            onClick={isResendDisabled ? null : handleResendClick}
                            disabled={isResendDisabled}
                        >
                            {resendTime === 0 ? 'Request Again' : `Request Again in 00:${resendTime}`}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpModal;
